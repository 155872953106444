import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import useLoginByWallet from "src/hooks/useLoginByWallet";

const MyButton = (props: any) => {
  const { children, theme = "normal", onClick, disabled = false } = props;
  return (
    <div
      className={`${styles["connect-button"]} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const PageInvite = () => {
  // const { activate } = useWeb3React();
  const { t } = useTranslation();

  const connectFn = useLoginByWallet();

  // const onConnectByWallet = async () => {
  //   if (typeof window.ethereum === 'undefined') {
  //     console.log('invite show()');
  //     return;
  //   }
  //   try {
  //     // await activate(injected);
  //     connectFn();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "-75px",
      }}
    >
      <div
        className={styles["login-warp-cover"]}
        style={{
          zIndex: 0,
          width: "100%",
          height: "100vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url('https://assets.crystalfun.io/frontend/assets/img/login/login-back.jpg')`,
          position: "absolute",
        }}
      ></div>
      <div
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "-250px",
        }}
      >
        <img
          style={{ width: "24vw", marginBottom: "40px" }}
          src="https://assets.crystalfun.io/frontend/assets/img/login/outer-logo.png"
          alt=""
        />
        <h1
          style={{
            fontSize: "36px",
            textTransform: "uppercase",
            marginBottom: "20px",
            color: "#fff",
            fontWeight: "500",
            lineHeight: "1.2",
          }}
        >
          {/* Connect */}
          {t("user.connect_tg")}
        </h1>
        <MyButton onClick={connectFn}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <svg
              style={{ width: "16px", marginRight: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              className="log-icon fill-black-icon"
              viewBox="0 0 16 16"
            >
              <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path>
            </svg>
            {/* Connect Wallet */}
            {t("user.connect_wallet")}
          </div>
        </MyButton>
      </div>
    </div>
  );
};

export default PageInvite;
